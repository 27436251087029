import React from 'react'
// import Loader from '../../Components/loader/Loader';
import Header from '../../Components/Header/Header';
import ListeServices from '../../Components/Services/ListeServices';
import Footer from '../../Components/footer/Footer';
import { useTranslation } from 'react-i18next';
import CtaQuotation from '../../Components/CtaQuotation/CtaQuotation';



function Services() {

    const { t } = useTranslation();

    return (
        < >
            {/* <Loader /> */}
            <Header />
            <div className='smooth-wrapper'>
                <div className='smooth-content'>

                    {/* Banner services */}
                    <section className="banner banner-service">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner__content">
                                        <h2 className="animation__char_come">{t('serviceintro')}</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="/">{t('breadcambhome')}</a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {t('serviceintro')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* EndBanner services */}

                    {/* list Services */}
                    <ListeServices />
                    {/* End List services */}

                    {/* Features */}
                    <section className="section feature cmn-animation">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-8 col-lg-8
                                col-xxl-7">
                                    <div className="section__header ">
                                        <h6 className="title-anim">
                                            {t('servicefeatures')}
                                        </h6>
                                        <h2 className="title-anim">
                                            {t('servicefeaturesSub')}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row gaper justify-content-center">
                                <div className="col-12 col-md-6 col-xl-6
                                cmn-animation-item">
                                    <div className="feature__single pb-3">
                                        <div className="feature__single-thumb">
                                            <a href="service-details.html">
                                                <i className="icon-price-tag"></i>
                                            </a>
                                        </div>
                                        <div className="feature__single-content">
                                            <h4>
                                                <a href="service-details.html">
                                                    {t('servicefeatures1')}
                                                </a>
                                            </h4>
                                            <p>
                                                {t('servicefeatures1Text')}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-6
                                cmn-animation-item">
                                    <div className="feature__single pb-3">
                                        <div className="feature__single-thumb">
                                            <a href="service-details.html">
                                                <i className="icon-track"></i>
                                            </a>
                                        </div>
                                        <div className="feature__single-content">
                                            <h4>
                                                <a href="service-details.html">
                                                    {t('servicefeatures2')}
                                                </a>
                                            </h4>
                                            <p>
                                                {t('servicefeatures2Text')}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-6
                                cmn-animation-item">
                                    <div className="feature__single pb-3">
                                        <div className="feature__single-thumb">
                                            <a href="service-details.html">
                                                <i className="icon-ware-three"></i>
                                            </a>
                                        </div>
                                        <div className="feature__single-content">
                                            <h4>
                                                <a href="service-details.html">
                                                    {t('servicefeatures3')}
                                                </a>
                                            </h4>
                                            <p>
                                                {t('servicefeatures3Text')}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                
                                <div className="col-12 col-md-6 col-xl-6
                                cmn-animation-item">
                                    <div className="feature__single pb-3">
                                        <div className="feature__single-thumb">
                                            <a href="service-details.html">
                                                <i className="icon-ware-three"></i>
                                            </a>
                                        </div>
                                        <div className="feature__single-content">
                                            <h4>
                                                <a href="service-details.html">
                                                    {t('servicefeatures4')}
                                                </a>
                                            </h4>
                                            <p>
                                                {t('servicefeatures4Text')}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End features */}


                    {/* Call to action */}
                        <CtaQuotation />
                    {/* End call to action */}

                    {/* Footer */}
                    <Footer />
                    {/* End footer */}
                </div>
            </div>
        </>
    )
}

export default Services